<template>
  <div
    class="page-content-box"
    :style="{
      backgroundImage: 'url(' + pagebg1 + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain'
    }"
  >
    <div
      class="info-banner"
      :style="{
        backgroundImage: 'url(' + bannerImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }"
    >
      <head-main class="no-fixed"></head-main>
    </div>
    <div class="infomation-box pd-5">
      <div class="zc-top-box">
        <div class="zc-top-l">
          <i class="zc-icon"></i>
          <span>焦点政策</span>
        </div>
        <div class="zc-top-r">
          <el-carousel class="custon-carousel" :interval="10000" arrow="never" height="200px">
            <el-carousel-item v-for="(item, i) in swipeList" :key="i">
              <div class="zc-title-box">
                <h1 class="link" @click="toContent(item)">{{ item.contentName }}</h1>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="zc-content-box">
        <div class="zx-conb-l">
          <div class="zc-search">
            <h5 class="page-tit-s">
              <i class="tit-icon1"></i>
              <span class="tit-red">政策</span>搜索
              <i class="tit-icon2"></i>
            </h5>
            <div class="zx-searc-box">
              <div class="text-seach-box">
                <span class="txt-span">关键词</span>
                <el-autocomplete
                  popper-class="my-autocomplete"
                  v-model="queryText"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  @select="handleSelect"
                  @keyup.enter.native="queryRegulationList()"
                >
                  <!-- <i class="el-icon-circle-close el-input__icon" slot="suffix" @click="handleIconClick"> </i> -->
                  <template slot-scope="{ item }">
                    {{ item.value }}
                  </template>
                </el-autocomplete>
              </div>
              <el-button class="txt-search" @click="queryRegulationList()">搜索</el-button>
            </div>

            <div class="zx-seach-list" v-if="regulationListFiltered">
              <h5 class="zx-seach-total">搜索结果（{{ regulationListFiltered.length }}条）</h5>
              <ul class="txt-list">
                <li v-for="item in regulationListFiltered" :key="item.id">
                  <span class="cir-icon"><i class="txt-circle"></i></span>
                  <p>
                    <!-- <span class="gj-txt">{{ item.gj }}</span> -->
                    <span class="s-txt" @click="toContent(item)">{{ item.contentName }}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="zx-contac-box">
            <div class="webcat-box">
              <img :src="webcat" />
              <p>打开微信扫扫一扫</p>
              <p>关注南平非遗保护中心官方公众号</p>
            </div>
          </div>
        </div>
        <div class="zx-conb-r">
          <h5 class="page-tit-s border-0">
            <i class="tit-icon1"></i>
            <span class="tit-red">政策</span>法规
            <i class="tit-icon2"></i>
          </h5>
          <div class="zx-tbl-box">
            <el-table :data="regulationList" style="width: 100%">
              <el-table-column type="index" width="90"> </el-table-column>
              <el-table-column prop="contentName" label="文件名">
                <template slot-scope="{ row }">
                  <a class="link" @click="toContent(row)">{{ row.contentName }}</a>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="时间" width="140">
                <template slot-scope="{ row }">
                  {{ row.createTime.split(' ')[0] }}
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="custon-page" v-if="pageCount > 1">
            <el-button class="page-btn" @click="handleCurrentChange(1)"><span>首页</span></el-button>
            <el-pagination
              :current-page="current"
              layout="pager,slot,jumper"
              :pager-count="7"
              :page-count="pageCount"
              :total="total"
              @current-change="handleCurrentChange"
            >
              <el-button class="page-btn" @click="handleCurrentChange(pageCount)"><span>尾页</span></el-button>
              <span class="totals">第{{ current }}页/共{{ pageCount }}页</span>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <botto-com class="bottom-white no-fixed"></botto-com>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
export default {
  components: {
    HeadMain,
    BottoCom
  },
  data() {
    return {
      webcat: require('../../assets/images/webcat.png'),
      queryText: '',
      current: 1,
      total: 0,
      pageCount: 0,
      bannerImg: require('../../assets/images/zc-banner.jpg'),
      pagebg1: require('../../assets/images/page-bg1.jpg'),
      swipeList: [],
      regulationList: [],
      regulationListFiltered: null
    }
  },
  methods: {
    getRegulationList() {
      this.axios
        .get('/api/portal/page/template-data', {
          params: {
            columnId: this.$route.query.id,
            current: this.current
          }
        })
        .then(res => {
          if (res.data.cellData) {
            this.swipeList = res.data.cellData.swipe.records
            let list = res.data.cellData.list
            this.regulationList = list.records
            this.total = list.total
            this.pageCount = list.pages
          }
        })
    },
    queryRegulationList() {
      if (this.queryText) {
        this.axios
          .get('/api/portal/page/template-data', {
            params: {
              columnId: this.$route.query.id,
              current: this.current,
              contentName: this.queryText
            }
          })
          .then(res => {
            if (res.data.cellData) {
              let list = res.data.cellData.search
              this.regulationListFiltered = list.records
            }
          })
      } else {
        this.regulationListFiltered = null
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    handleSelect(item) {
      console.log(item)
    },
    handleIconClick(ev) {
      console.log(ev)
    },
    handleCurrentChange(current) {
      this.current = current
      this.getRegulationList()
    },
    toContent({ contentUrl, id, contentId }) {
      this.$router.push({ path: contentUrl, query: { id, contentId } })
    }
  },
  mounted() {
    this.getRegulationList()
    this.restaurants = [
      // { value: '三全鲜食（北新泾店）' },
      // { value: 'Hot honey 首尔炸鸡（仙霞路）' },
      // { value: '新旺角茶餐厅' },
      // { value: '泷千家(天山西路店)' },
      // { value: '胖仙女纸杯蛋糕（上海凌空店）' },
      // { value: '贡茶' },
      // { value: '豪大大香鸡排超级奶爸' }
    ]
  }
}
</script>
<style lang="scss" scoped>
.link:hover {
  color: #b93333;
  cursor: pointer;
  text-decoration: underline;
}
</style>
