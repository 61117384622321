var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content-box",style:({
    backgroundImage: 'url(' + _vm.pagebg1 + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain'
  })},[_c('div',{staticClass:"info-banner",style:({
      backgroundImage: 'url(' + _vm.bannerImg + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    })},[_c('head-main',{staticClass:"no-fixed"})],1),_c('div',{staticClass:"infomation-box pd-5"},[_c('div',{staticClass:"zc-top-box"},[_vm._m(0),_c('div',{staticClass:"zc-top-r"},[_c('el-carousel',{staticClass:"custon-carousel",attrs:{"interval":10000,"arrow":"never","height":"200px"}},_vm._l((_vm.swipeList),function(item,i){return _c('el-carousel-item',{key:i},[_c('div',{staticClass:"zc-title-box"},[_c('h1',{staticClass:"link",on:{"click":function($event){return _vm.toContent(item)}}},[_vm._v(_vm._s(item.contentName))])])])}),1)],1)]),_c('div',{staticClass:"zc-content-box"},[_c('div',{staticClass:"zx-conb-l"},[_c('div',{staticClass:"zc-search"},[_vm._m(1),_c('div',{staticClass:"zx-searc-box"},[_c('div',{staticClass:"text-seach-box"},[_c('span',{staticClass:"txt-span"},[_vm._v("关键词")]),_c('el-autocomplete',{attrs:{"popper-class":"my-autocomplete","fetch-suggestions":_vm.querySearch,"placeholder":"请输入内容"},on:{"select":_vm.handleSelect},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.queryRegulationList()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.queryText),callback:function ($$v) {_vm.queryText=$$v},expression:"queryText"}})],1),_c('el-button',{staticClass:"txt-search",on:{"click":function($event){return _vm.queryRegulationList()}}},[_vm._v("搜索")])],1),(_vm.regulationListFiltered)?_c('div',{staticClass:"zx-seach-list"},[_c('h5',{staticClass:"zx-seach-total"},[_vm._v("搜索结果（"+_vm._s(_vm.regulationListFiltered.length)+"条）")]),_c('ul',{staticClass:"txt-list"},_vm._l((_vm.regulationListFiltered),function(item){return _c('li',{key:item.id},[_vm._m(2,true),_c('p',[_c('span',{staticClass:"s-txt",on:{"click":function($event){return _vm.toContent(item)}}},[_vm._v(_vm._s(item.contentName))])])])}),0)]):_vm._e()]),_c('div',{staticClass:"zx-contac-box"},[_c('div',{staticClass:"webcat-box"},[_c('img',{attrs:{"src":_vm.webcat}}),_c('p',[_vm._v("打开微信扫扫一扫")]),_c('p',[_vm._v("关注南平非遗保护中心官方公众号")])])])]),_c('div',{staticClass:"zx-conb-r"},[_vm._m(3),_c('div',{staticClass:"zx-tbl-box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.regulationList}},[_c('el-table-column',{attrs:{"type":"index","width":"90"}}),_c('el-table-column',{attrs:{"prop":"contentName","label":"文件名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('a',{staticClass:"link",on:{"click":function($event){return _vm.toContent(row)}}},[_vm._v(_vm._s(row.contentName))])]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"时间","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.createTime.split(' ')[0])+" ")]}}])})],1)],1),(_vm.pageCount > 1)?_c('div',{staticClass:"custon-page"},[_c('el-button',{staticClass:"page-btn",on:{"click":function($event){return _vm.handleCurrentChange(1)}}},[_c('span',[_vm._v("首页")])]),_c('el-pagination',{attrs:{"current-page":_vm.current,"layout":"pager,slot,jumper","pager-count":7,"page-count":_vm.pageCount,"total":_vm.total},on:{"current-change":_vm.handleCurrentChange}},[_c('el-button',{staticClass:"page-btn",on:{"click":function($event){return _vm.handleCurrentChange(_vm.pageCount)}}},[_c('span',[_vm._v("尾页")])]),_c('span',{staticClass:"totals"},[_vm._v("第"+_vm._s(_vm.current)+"页/共"+_vm._s(_vm.pageCount)+"页")])],1)],1):_vm._e()])])]),_c('botto-com',{staticClass:"bottom-white no-fixed"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zc-top-l"},[_c('i',{staticClass:"zc-icon"}),_c('span',[_vm._v("焦点政策")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"page-tit-s"},[_c('i',{staticClass:"tit-icon1"}),_c('span',{staticClass:"tit-red"},[_vm._v("政策")]),_vm._v("搜索 "),_c('i',{staticClass:"tit-icon2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"cir-icon"},[_c('i',{staticClass:"txt-circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"page-tit-s border-0"},[_c('i',{staticClass:"tit-icon1"}),_c('span',{staticClass:"tit-red"},[_vm._v("政策")]),_vm._v("法规 "),_c('i',{staticClass:"tit-icon2"})])}]

export { render, staticRenderFns }